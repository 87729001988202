import React from 'react';
import { DIGISAC_WEBCHAT_URL } from '../../../../config/constants';
import useScript from '../../../hooks/use-script/use-script';
import './digisac-chat.scss'

export interface DigisacChatProps {
    id: string;
}

declare global {
    interface Window {
        _digisac?: { id?: string };
    }
}

export const DigisacChat = (props: DigisacChatProps) => {
    window._digisac = { id: props.id };
    useScript(DIGISAC_WEBCHAT_URL);
    return <></>;
};

export default DigisacChat;
