import React from 'react';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { useInviteToAcceptState } from '../../reducer/hooks';

export interface UserInviteInfo {
    email?: string;
    companyName?: string;
    companyIdentification?: string;
}

export const useInviteInfo = (): UserInviteInfo => {
    const { status, invite, company } = useInviteToAcceptState();

    const [email, setEmail] = React.useState<string | undefined>(undefined);
    const [companyName, setCompanyName] = React.useState<string | undefined>(undefined);
    const [companyIdentification, setCompanyIdentification] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS) {
            setEmail(invite?.email);
            setCompanyName(company?.name);
            setCompanyIdentification(company?.identification);
        }
    }, [status, invite, company]);

    return { email, companyName, companyIdentification };
};

export default useInviteInfo;
