import React from 'react';
import { INTERCOM_WEBCHAT_URL } from '../../../../config/constants';
import useScript from '../../../hooks/use-script/use-script';

declare global {
    interface Window {
        Intercom?: (...args: any) => any;
        intercomSettings?: any;
        attachEvent?: (...args: any) => any;
    }
}

export interface IntercomChatProps {
    id: string;
}

export const IntercomChat = (props: IntercomChatProps) => {
    const intercom = window.Intercom;
    if (intercom) {
        intercom('reattach_activator');
        intercom('update', window.intercomSettings);
    } else {
        // tslint:disable-next-line:only-arrow-functions
        const i: any = function() {
            i.c(arguments);
        };
        i.q = [];
        i.c = (args: any) => i.q.push(args);
        window.Intercom = i;
    }
    useScript(`${INTERCOM_WEBCHAT_URL}${props.id}`);

    React.useEffect(() => {
        if (intercom) {
            intercom('boot', {});
        }
    }, [intercom]);

    return <></>;
};

export default IntercomChat;
