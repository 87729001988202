import React from 'react';
import { JIVO_WEBCHAT_URL } from '../../../../config/constants';
import useInviteInfo, { UserInviteInfo } from '../../../hooks/use-invite-info';
import useScript from '../../../hooks/use-script/use-script';
import { entriesOfObject } from '../../../util/object-utils';
import { isNotBlankString } from '../../../util/string-utils';
import './jivo-chat.scss';

export interface JivoChatProps {
    id: string;
}

interface JivoApi {
    setUserToken: (...args: any) => any;
    setContactInfo: (...args: any) => any;
    setCustomData: (...args: any) => any;
}

declare global {
    interface Window {
        jivo_onLoadCallback: (...args: any) => any;
        jivo_api?: JivoApi;
    }
}

interface ContactInfo {
    email?: string;
}

interface CustomData {
    [key: string]: string | undefined;
    companyName?: string;
    companyIdentification?: string;
}

export const JivoChat = (props: JivoChatProps) => {
    useScript(`${JIVO_WEBCHAT_URL}${props.id}`);

    const inviteInfo: UserInviteInfo = useInviteInfo();

    const [contactInfo, setContactInfo] = React.useState<ContactInfo>({});
    const [customData, setCustomData] = React.useState<CustomData>({});

    React.useEffect(() => {
        setContactInfo(ps => (ps.email !== inviteInfo.email ? { email: inviteInfo.email } : ps));
        setCustomData(ps => {
            if (ps.companyName !== inviteInfo.companyName || ps.companyIdentification !== inviteInfo.companyIdentification) {
                return {
                    companyName: inviteInfo.companyName,
                    companyIdentification: inviteInfo.companyIdentification
                };
            }
            return ps;
        });
    }, [inviteInfo]);

    window.jivo_onLoadCallback = () => {
        if (window.jivo_api) {
            window.jivo_api.setContactInfo(contactInfo);
            window.jivo_api.setCustomData(
                entriesOfObject(customData)
                    .filter(([_key, value]) => isNotBlankString(value))
                    .map(([key, value]) => {
                        return { title: key, content: value };
                    })
            );
        }
    };

    return <></>;
};

export default JivoChat;
