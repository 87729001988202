import React from 'react';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { useOrganizationChatState, useRootDispatch } from '../../reducer/hooks';
import { organizationChatRequest } from '../../reducer/organization/chat/actions';
import DigisacChat from './components/digisac/digisac-chat';
import IntercomChat from './components/intercom/intercom-chat';
import JivoChat from './components/jivo/jivo-chat';

const useOrganizationChatRequest = () => {
    const dispatch = useRootDispatch();
    const state = useOrganizationChatState();

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.NOOP) {
            dispatch(organizationChatRequest());
        }
    }, [dispatch, state.status]);
};

export const WebChat = () => {
    useOrganizationChatRequest();
    const { organization, status } = useOrganizationChatState();

    if (status !== HttpRequestStatus.SUCCESS) {
        return <></>;
    }

    if (organization?.intercomEnabled && organization.intercomApiKey) {
        return <IntercomChat id={organization.intercomApiKey} />;
    }

    if (organization?.jivoEnabled && organization.jivoApiKey) {
        return <JivoChat id={organization.jivoApiKey} />;
    }

    if (organization?.digisacEnabled && organization.digisacApiKey) {
        return <DigisacChat id={organization.digisacApiKey} />;
    }

    return <></>;
};

export default WebChat;
